import React from 'react';
import {BrowserRouter as Router, Route,NavLink,HashRouter,Redirect,Link} from "react-router-dom";

//import { Route, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { setMobileNavVisibility } from '../../reducers/Layout';
//import { withRouter } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import CompanyProfile from './CompanyProfile';
import UserProfile from './UserProfile';
import UsersList from './UsersList';
import UsersArchivedList from './UsersArchivedList';
// import UserList from './UserList';
import NewUser from './NewUser';
import NewCompanyProfile from './NewCompanyProfile';
import UserDashboard from '../UserDashboard';
import StatsSettings from './StatsSettings';
import SubContractorsPage from "../UserDashboard/SubContractorsPage";
import '../../assets/styles/themes.scss';
import '../../../assets/stylesheets/common.css';
import '../../assets/styles/RAT/style.scss';
import '../../assets/styles/ATT/style.scss';
import '../../assets/styles/plans.scss';
import "../../assets/styles/docs.scss"
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/table.scss';
import '../../assets/styles/asset-config.scss';
import '../../assets/styles/inventory.scss';
import '../../assets/styles/time_card.scss';
import '@assets/styles/InternalDocs/style.scss'


class Main extends React.Component{

    constructor(props){
       super();
    }

    render() {
    return (
        <Router>
            <div className="main-wrap">
                <SideBar/>
                <NavBar/>
                <Route exact path="/" component={UserDashboard}/>
                <Route exact path="/login" component={UserDashboard}/>
                <Route exact path="/dashboard" component={UserDashboard}/>
                <Route exact path ="/company-profile" component={CompanyProfile} />
                <Route path ="/user-profile" component={UserProfile} />
                <Route path ="/company-users" component={UsersList} />
                <Route path ="/company-archived-users" component={UsersArchivedList} />
                <Route path ="/new-user" component={NewUser} />
                <Route path ="/stats-settings" component={StatsSettings} />
                <Route path ="/new-company" component={NewCompanyProfile} />
                <Route path="/sub-contractor" component={SubContractorsPage} />
                <Footer/>
            </div>
        </Router>
        )}
}

export default Main;
