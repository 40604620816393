import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { User } from "../pages/HomePage/Login";
import { setUserCookieData } from "../utils/util";
import Cookies from "js-cookie";
import TimeTrackingIcon from "../assets/images/time_tracking_white.svg";
const initialState = { company: "", last_updated: "" };

// toggle sidebar collapse
$(function () {
  $(".btn-toggle-sidebar").on("click", function () {
    $(".wrapper").toggleClass("sidebar-collapse");
  });
  // mark sidebar item as active when clicked
  $(".sb-item").on("click", function () {
    if ($(".wrapper").hasClass("sidebar-collapse")) {
      Cookies.set("collapse", true);
    } else {
      Cookies.set("collapse", false);
    }

    if ($(this).hasClass("btn-toggle-sidebar")) {
      return; // already actived
    }
    $(this).siblings().removeClass("active");
    $(this).siblings().find(".sb-item").removeClass("active");
    $(this).addClass("active");
  });
});

const SideNav = () => {
  const [userData, setUserData] = useState(initialState);

  const client = userData.company;
  const encoded_company_name = userData.encoded_company_name;
  const updatedDate = userData.last_updated;
  const superAdmin = User.role;
  const filterProject = "";
  const roles = ["Admin", "SuperAdmin", "Manager"];

  useLayoutEffect(() => {
    if (Cookies.get("collapse") === "true") {
      $(".wrapper").toggleClass("sidebar-collapse");
    }
  }, []);

  const handleClick = () => {
    window.location = "/company_documents#required-documents";
  };

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get("/analytics_dashboard/user_info", {
        params: {},
      });
      setUserData(result.data);
      setUserCookieData(result.data);
    }
    fetchData().then((r) => r);
  }, []);

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sb-item-list">
          {/* Users & Groups */}
          {(User.role == "Admin" ||
            User.role == "Manager" ||
            superAdmin == "SuperAdmin") && (
            <a href="/users-and-groups">
              <div className="sb-item">
                <i className="sb-icon fa fa-users"></i>
                <span className="sb-text">Users &amp; Groups</span>
              </div>
            </a>
          )}
          {/* Dashboard */}
          <div className="sb-item sb-menu">
            <i className="sb-icon fa fa-tachometer"></i>
            <span className="sb-text">Dashboard</span>
            <div className="sb-submenu">
              <a href="/">
                <div className="sb-item">
                  <span className="sb-text">Main Dashboard</span>
                </div>
              </a>
              <a href="/stats">
                <div className="sb-item">
                  <span className="sb-text">Stats Dashboard</span>
                </div>
              </a>
              {/* <a href="/reports_dashboards">
                <div className="sb-item">
                  <span className="sb-text">Reporting Dashboard</span>
                </div>
              </a> */}
            </div>
          </div>

          {/* Training */}
          <div className="sb-item sb-menu">
            <i className="sb-icon fa fa-graduation-cap"></i>
            <span className="sb-text">Training</span>
            <div className="sb-submenu">
              <a href="/rot_dashboard">
                <div className="sb-item">
                  <span className="sb-text">Records of Training</span>
                </div>
              </a>
              <a href="/trainings/employees_trainings">
                <div className="sb-item">
                  <span className="sb-text">Training Matrix</span>
                </div>
              </a>
            </div>
          </div>
          {/* Forms */}
          <div className="sb-item sb-menu">
            <i className="sb-icon fa fa-file-text"></i>
            <span className="sb-text">Forms</span>
            <div className="sb-submenu">
              <a href="/template/new_forms">
                <div className="sb-item">
                  <span className="sb-text">Start New Form</span>
                </div>
              </a>
              <a href="/forms">
                <div className="sb-item">
                  <span className="sb-text">Completed Forms</span>
                </div>
              </a>
              <a href="/template">
                <div className="sb-item">
                  <span className="sb-text">Template Creator</span>
                </div>
              </a>
            </div>
          </div>
          {/* Documents */}
          <div className="sb-item sb-menu">
            <i className="sb-icon fa fa-check-square-o"></i>
            <span className="sb-text">Documents</span>
            <div className="sb-submenu">
              {/* <a
                href="/company_documents"
              >
                <div className="sb-item">
                  <span className="sb-text">
                    Company Documents
                  </span>

                </div>
              </a> */}
              <a href="/company_documents_beta">
                <div className="sb-item">
                  <span className="sb-text">Company Documents</span>
                </div>
              </a>
              <a
                href="/company_documents#required-documents"
                onClick={() => handleClick()}
              >
                <div className="sb-item">
                  <span className="sb-text">Required Documents</span>
                </div>
              </a>
              <a href="/subcontractor_matrix">
                <div className="sb-item">
                  <span className="sb-text">Document Matrix</span>
                </div>
              </a>
            </div>
          </div>

          {/* Risk Assessment */}

          {(User.role == "Admin" ||
            User.role == "Manager" ||
            superAdmin == "SuperAdmin") && (
            <>
              <a href="/risk-assessments">
                <div className="sb-item">
                  <i className="sb-icon fa fa-area-chart"></i>
                  <span className="sb-text">Risk Assessment</span>
                </div>
              </a>

              {/* Assets */}

              <a href="/asset-tracking">
                <div className="sb-item">
                  <i className="sb-icon fa fa-truck"></i>
                  <span className="sb-text"> Assets</span>
                </div>
              </a>

              {/* Assets */}

              {/* Emergency Plans */}

              <a href="/emergency-plans">
                <div className="sb-item">
                  <i className="sb-icon fa fa-exclamation-triangle"></i>
                  <span className="sb-text">Emergency Plans</span>
                </div>
              </a>

              {/* Subcontractors */}

              {/* <a href="/subcontractor_dashboard/subcontractor_list">
                <div className="sb-item">
                  <i className="sb-icon fa fa-address-card"></i>
                  <span className="sb-text">Subcontractors</span>
                </div>
              </a> */}

              <a href="/subcontractors">
                <div className="sb-item">
                  <i className="sb-icon fa fa-address-card"></i>
                  <span className="sb-text">Subcontractors</span>
                </div>
              </a>
            </>
          )}


          {(User.isInventoryEnabled() || userData.inventory_picker || User.role == "Admin") && (
            <a href="/inventory">
            <div className="sb-item">
              <i className="sb-icon fa fa-warehouse"></i>
              <span className="sb-text">Inventory</span>
            </div>
            </a>
          )}

          {(User.isTimeTrackingEnabled() || (User.role == "Admin" || User.role == "Manager")) && (
              <a href="/time-tracking">
              <div
                className="sb-item"
                style={{ display: 'flex', alignItems: 'center',height:'45px' }}
              >
                <img
                  src={TimeTrackingIcon}
                  alt="Time Tracking"
                  style={{ paddingInlineStart: '5px', paddingRight: '15px' }}
                />
                <span
                  className="sb-text"
                  style={{
                    
                    
                    
                    marginLeft: '45px',
                    lineHeight:'45px'
                  }}
                >
                  Time Tracking
                </span>
                <span
                  style={{
                    border: '1px #3fb950 solid',
                    borderRadius: '10px',
                    color: '#3fb950',
                    padding: '1px 5px',
                    marginLeft: '115px',
                    fontSize: '10px',
                    height:'18px',
                    lineHeight:'15px'
                  }}
                >
                  New
                </span>
              </div>
            </a>
          )}

          {/* QR Codes */}

          {/* {(User.role == "Admin" || superAdmin == "SuperAdmin") && (
            <div className="sb-item sb-menu"><i className="sb-icon fa fa-qrcode"></i><span className="sb-text">QR Codes</span>

              <div className="sb-submenu">
                <div className="sb-item">
                  <span className="sb-text">
                    <a href="/qr_codes">Group QR Codes</a>
                  </span>
                </div>
                <div className="sb-item">
                  <span className="sb-text">
                    <a href="/templates_qr_codes">Form Templates QR Codes</a>
                  </span>
                </div>
              </div>
            </div>
          )} */}

          {/* QR Codes - Beta */}

          {(User.role == "Admin" || superAdmin == "SuperAdmin") && (
            <a href="/qrcodes">
              <div className="sb-item">
                <i className="sb-icon fa fa-qrcode"></i>

                <span className="sb-text">QR Codes</span>
              </div>
            </a>
          )}

          {/* Actions & Notices */}

          <a href="/actions">
            <div className="sb-item">
              <i className="sb-icon fas fa-running"></i>
              <span className="sb-text">Actions</span>
            </div>
          </a>

          <a href="/todo-management">
            <div className="sb-item">
              <i className="sb-icon fas fa-list-alt"></i>
              <span className="sb-text">TO DO List</span>
            </div>
          </a>

          {/* Release Notes */}

          {(User.role == "Admin" || superAdmin == "SuperAdmin") && (
            <a href="/release_notes">
              <div className="sb-item">
                <i className="sb-icon fa fa-sticky-note"></i>
                <span className="sb-text">Release Notes</span>
              </div>
            </a>
          )}

          {/* Press Releases */}

          {superAdmin == "SuperAdmin" && (
            <a href="/press_releases">
              <div className="sb-item">
                <i className="sb-icon fa fa-sticky-note"></i>
                <span className="sb-text">Press Releases</span>
              </div>
            </a>
          )}

          {/* BI Analytics */}

          {User.role == "Admin" &&
            client &&
            (client.toLowerCase().trim() === "centrecourt" ||
              client.toLowerCase().trim() === "state window corporation" ||
              client.toLowerCase().trim() === "e.s. fox" ||
              client.toLowerCase().trim() === "van mar constructors on inc." ||
              client.toLowerCase().trim() === "verdi group of companies" ||
              client.toLowerCase().trim() === "day group"  ||
              client.toLowerCase().trim() === "corebuild construction ltd."  ||
              client.toLowerCase().trim() === "demolition plus") && (
              <a href="/analytics-v1">
                <div className="sb-item">
                  <i className="sb-icon fa fa-pie-chart"></i>
                  <span className="sb-text">BI Analytics</span>
                </div>
              </a>
            )}

          {/* Ctra Duplication */}

          {superAdmin == "SuperAdmin" && (
            <a href="/ctra?company=duplicate">
              <div className="sb-item">
                <i className="sb-icon fa fa-clone"></i>
                <span className="sb-text">Ctra Duplication</span>
              </div>
            </a>
          )}

          <hr />

          {/* Company Profile */}

          {(User.role == "Admin" || superAdmin == "SuperAdmin") && (
            <>
              <a href="/company_profile">
                <div className="sb-item">
                  <i className="sb-icon fa fa-building"></i>
                  <span className="sb-text">Company Profile</span>
                </div>
              </a>

              {/* Stats Settings */}

              <a href="/stats-settings">
                <div className="sb-item">
                  <i className="sb-icon fa fa-sliders-h"></i>
                  <span className="sb-text">Stats Settings</span>
                </div>
              </a>
            </>
          )}

          {/* My Profile */}

          <a href="/user-profile">
            <div className="sb-item">
              <i className="sb-icon fa fa-user-circle"></i>
              <span className="sb-text">My Profile</span>
            </div>
          </a>

          {/* Knowledge Base */}

          <a href="http://knowledge.safetyloop.com">
            <div className="sb-item">
              <i className="sb-icon fa fa-book-open"></i>
              <span className="sb-text">Knowledge Base</span>
            </div>
          </a>
          <span
            style={{
              color: "#fff",
              fontSize: "10px",
              marginBottom: "auto",
              marginLeft: "10px",
            }}
          >
            {User.version}
          </span>

          <br />
          <br />
          <br />
          <br />

          <div className="btn-toggle-sidebar sb-item">
            <i className="sb-icon fa fa-angle-double-left"></i>
            <span className="sb-text"></span>
            <i className="sb-icon fa fa-angle-double-right"></i>
          </div>
        </div>
      </div>
      <div className="main"></div>
    </div>
  );
};

export default SideNav;
