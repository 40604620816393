import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function TrainingRecordManagement() {
    return (
        <>
            <HeroSection
                heroText={"Training Record Management"}
                subText={"Manage employee training records and certifications effortlessly with SafetyLoop's tools."}
            />
            <FeaturesSection
                headerText={"Centralized Training Records"}
                subText={"Maintain a centralized repository for employee training records and certifications."}
                featureHighlights={[
                    "Store and manage all training records in one place.",
                    "Track training status and completion rates.",
                    "Set automated reminders for training renewals.",
                    "Generate reports for training compliance."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Training Records section here
            />
            <FeaturesSection
                headerText={"Training Compliance"}
                subText={"Ensure compliance with training requirements by monitoring certifications and training renewals."}
                featureHighlights={[
                    "Track certification expiry dates.",
                    "Set up alerts for upcoming renewals.",
                    "Maintain detailed compliance history."
                ]}
                // Placeholder: Add image for Training Compliance section here
            />
            <FeaturesSection
                headerText={"Employee Training Insights"}
                subText={"Analyze training performance with detailed insights and reports."}
                featureHighlights={[
                    "Track employee training progress.",
                    "Identify gaps in training and take corrective actions.",
                    "Generate performance insights for training optimization."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Training Insights section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
