import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function CorrectiveActions() {
    return (
        <>
            <HeroSection
                heroText={"Corrective Actions"}
                subText={"Implement corrective actions to ensure safety and compliance with SafetyLoop's tools."}
            />
            <FeaturesSection
                headerText={"Manage Corrective Actions Effectively"}
                subText={"Track and manage corrective actions to address safety issues and maintain compliance."}
                featureHighlights={[
                    "Log corrective actions for identified issues.",
                    "Assign responsibilities and deadlines.",
                    "Monitor progress and ensure resolution.",
                    "Generate corrective action reports."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Corrective Actions Tracking section here
            />
            <FeaturesSection
                headerText={"Automated Corrective Action Alerts"}
                subText={"Receive real-time alerts and notifications for pending or overdue corrective actions."}
                featureHighlights={[
                    "Set up alerts for upcoming deadlines.",
                    "Receive notifications for overdue actions.",
                    "Track action completion and compliance."
                ]}
                // Placeholder: Add image for Automated Alerts section here
            />
            <FeaturesSection
                headerText={"Corrective Action Reports"}
                subText={"Generate detailed reports on corrective actions for compliance and safety audits."}
                featureHighlights={[
                    "Maintain a secure history of all corrective actions.",
                    "Generate compliance-ready reports.",
                    "Easily share corrective action status with stakeholders."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Corrective Action Reports section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
