import {
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import SearchIcon from "../../../assets/images/searchbar.svg";
import DefaultInventory from "../../../assets/fonts/DefaultInventory.svg";
import Sorting from "../../../assets/images/Filter.png";
import Tick from "../../../assets/fonts/Tick.svg";
import CloseIcon from "../../../assets/fonts/CloseIcon.svg";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Loader from "../../../components/NewLoader";
import OrderApprove from "./popup/ApproveReq";
import { withRouter } from "react-router-dom";

const initialState = {
  order_request_id_eq: "",
  order_request_created_by_full_name_cont: "",
  inventory_item_supplier_name_cont: "",
  order_request_group_name_cont: "",
  inventory_item_name_cont: "",
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE INPUT CHANGE":
      return { ...state, [action.field]: action.payload };
    case "HANDLE INPUT REMOVE":
      return { ...state, [action.field]: "" };
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const OrderRequest = ({ history }) => {
  const mounted = useRef(false);
  const [search, dispatch] = useReducer(searchReducer, initialState);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState();
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [sortedBy, setSortedBy] = useState("order_request_id");
  const [sort, setSort] = useState("desc");

  useEffect(() => {
    getPageDetails();
  }, [page, limit]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      mounted.current && getPageDetails();
      mounted.current = true;
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search, sortedBy, sort]);

  const getPageDetails = async () => {
    try {
      await axios
        .get(`${Application.api_url}/api/v2/order_request_items`, {
          params: {
            index: true,
            page: page,
            limit: limit,
            status_eq: "pending",
            "sorts[]": sortedBy + " " + sort,
            ...search,
          },
        })
        .then((res) => {
          let details = res.data;
          setPageDetails(details ? details.data : []);
          setPageCount(
            details && details.metadata ? details.metadata.pages : 0
          );
          setCount(details && details.metadata ? details.metadata.count : 0);
          setLoading(false);
        })
        .catch((err) => console.log("err=>", err));
    } catch (err) {
      console.log("err===>", err);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickExpand = (event, id) => {
    const selectedIndex = expanded.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(expanded, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(expanded.slice(1));
    } else if (selectedIndex === expanded.length - 1) {
      newSelected = newSelected.concat(expanded.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          expanded.slice(0, selectedIndex),
          expanded.slice(selectedIndex + 1)
      );
    }

    setExpanded(newSelected);
  };

  const close = (data) => {
    setShow(false);
    setShowDetails("");
    data && setSelected([]);
    data && toast.success("Order Approved Successfully");
    data && getPageDetails();
  };

  const decline = async (data) => {
    try {
      await axios
        .put(
          `${Application.api_url}/api/v2/order_request_items/${data.identifier}`,
          {
            quantity: data.quantity,
            status: "declined",
            id: data.order_request.identifier,
          }
        )
        .then((result) => {
          if (result.data && result.data.success === true) {
            toast.success("Order Rejected Successfully");
            setSelected([]);
            getPageDetails();
          }
        })
        .catch((err) => console.log("err=>", err));
    } catch (err) {
      console.log("err==>", err);
    }
  };

  const handleTextChanges = (e) => {
    dispatch({
      type: "HANDLE INPUT CHANGE",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleTextRemove = (name) => {
    dispatch({
      type: "HANDLE INPUT REMOVE",
      field: name,
      payload: "",
    });
  };

  const handleMultiSelect = (data) => {
    let checkIndex = selected.findIndex(
      (val) => val.identifier == data.identifier
    );
    if (checkIndex !== -1) {
      let finalArray = selected.filter(
        (val) => val.identifier != data.identifier
      );
      setSelected(finalArray);
    } else {
      setSelected([...selected, ...[data]]);
    }
  };

  const BulkDecline = () => {
    selected.map((val) => {
      decline(val);
    });
  };

  const BulkApprove = () => {
    setShowDetails(selected);
    setShow(true);
  };

  const handleSort = (name) => {
    if (sortedBy == name) {
      setSort(sort == "asc" ? "desc" : "asc");
    } else {
      setSortedBy(name);
      setSort("asc");
    }
  };

  const goTo = async (path) => {
    history.push(path);
  };

  const isSelected = (data) => {
    let index = selected.findIndex((val) => val.identifier == data);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const isExpanded = (requestID) => expanded.indexOf(requestID) !== -1;

  return (
    <div className="inventory-type">
      <div className="fields-setting">
        <div className="asset-active-filters">
          Active Filters :
          <span className="active-filters">
            <span>Order ID :</span>
            <span className="selected">
              {search.order_request_id_eq ? search.order_request_id_eq : "All"}
            </span>
            <span
              className={
                search.order_request_id_eq
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("order_request_id_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Inventory Name :</span>
            <span className="selected">
              {search.inventory_item_name_cont
                ? search.inventory_item_name_cont
                : "All"}
            </span>
            <span
              className={
                search.inventory_item_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("inventory_item_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Requested By :</span>
            <span className="selected">
              {search.order_request_created_by_full_name_cont
                ? search.order_request_created_by_full_name_cont
                : "All"}
            </span>
            <span
              className={
                search.order_request_created_by_full_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("order_request_created_by_full_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Supplier Name :</span>
            <span className="selected">
              {search.inventory_item_supplier_name_cont
                ? search.inventory_item_supplier_name_cont
                : "All"}
            </span>
            <span
              className={
                search.inventory_item_supplier_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("inventory_item_supplier_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span
            className="active-filters-clear"
            onClick={(e) => {
              dispatch({
                type: "RESET",
              });
            }}
          >
            Clear All
          </span>
        </div>
      </div>
      {selected.length > 0 && (
        <div className="multi-select">
          <div className="selected-users">
            <span>Selected Orders :</span>
            <span className="count">{selected.length}</span>
          </div>
          <div className="order-confirm" onClick={() => BulkApprove()}>
            <img src={Tick} alt="tick" />
            <span>Order Confirm </span>
          </div>
          <div className="order-confirm" onClick={() => BulkDecline()}>
            <img src={CloseIcon} alt="tick" />
            <span>Order Decline </span>
          </div>
          {/* <span className="cancel">Cancel</span> */}
        </div>
      )}
      <div className="page-content">
        <table>
          <thead>
            <tr>
              <th className="divider star-col">
                <span className="col-name sorting-col">
                  Order ID
                  <img
                    src={Sorting}
                    alt=""
                    className={`${
                      sortedBy == "order_request_id" && sort == "asc"
                        ? "order-desc"
                        : ""
                    }`}
                    onClick={() => {
                      handleSort("order_request_id");
                    }}
                  />
                </span>
                {pageDetails.length > 0 && (
                  <div className="search-col order-req-id">
                    <input
                      type="text"
                      name="order_request_id_eq"
                      id="order_request_id_eq"
                      className="search-btn"
                      value={search.order_request_id_eq}
                      placeholder="Search"
                      onChange={(e) => {
                        handleTextChanges(e);
                      }}
                    />
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      className="search-icon"
                    />
                  </div>
                )}
              </th>
              <th className="divider inventory-name-col-2">
                <span className="col-name sorting-col">
                  Inventory Name
                  <img
                    src={Sorting}
                    alt=""
                    className={`${
                      sortedBy == "inventory_item_name" && sort == "asc"
                        ? "order-desc"
                        : ""
                    }`}
                    onClick={() => {
                      handleSort("inventory_item_name");
                    }}
                  />
                </span>
                {pageDetails.length > 0 && (
                  <div className="search-col inventory-name">
                    <input
                      type="text"
                      name="inventory_item_name_cont"
                      id="inventory_item_name_cont"
                      className="search-btn"
                      value={search.inventory_item_name_cont}
                      placeholder="Search"
                      onChange={(e) => {
                        handleTextChanges(e);
                      }}
                    />
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      className="search-icon"
                    />
                  </div>
                )}
              </th>
              <th className="divider">
                <span className="col-name">Request Qty</span>
              </th>
              <th className="divider">
                <span className="col-name sorting-col">
                  Requested By
                  <img
                    src={Sorting}
                    alt=""
                    className={`${
                      sortedBy == "order_request_created_by_full_name" &&
                      sort == "asc"
                        ? "order-desc"
                        : ""
                    }`}
                    onClick={() => {
                      handleSort("order_request_created_by_full_name");
                    }}
                  />
                </span>
                {pageDetails.length > 0 && (
                  <div className="search-col">
                    <input
                      type="text"
                      name="order_request_created_by_full_name_cont"
                      id="order_request_created_by_full_name_cont"
                      className="search-btn"
                      value={search.order_request_created_by_full_name_cont}
                      placeholder="Search"
                      onChange={(e) => {
                        handleTextChanges(e);
                      }}
                    />
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      className="search-icon"
                    />
                  </div>
                )}
              </th>
              <th className="divider">
                  <span className="col-name sorting-col">
                    Group
                    <img
                        src={Sorting}
                        alt=""
                        className={`${
                            sortedBy == "order_request_group_name" &&
                            sort == "asc"
                                ? "order-desc"
                                : ""
                        }`}
                        onClick={() => {
                          handleSort("order_request_group_name");
                        }}
                    />
                  </span>
                {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                          type="text"
                          name="order_request_group_name_cont"
                          id="order_request_group_name_cont"
                          className="search-btn"
                          value={search.order_request_group_name_cont}
                          placeholder="Search"
                          onChange={(e) => {
                            handleTextChanges(e);
                          }}
                      />
                      <img
                          src={SearchIcon}
                          alt="Search Icon"
                          className="search-icon"
                      />
                    </div>
                )}
              </th>
              <th className="divider">
                <span className="col-name sorting-col">
                  Supplier Name
                  <img
                    src={Sorting}
                    alt=""
                    className={`${
                      sortedBy == "inventory_item_supplier_name" &&
                      sort == "asc"
                        ? "order-desc"
                        : ""
                    }`}
                    onClick={() => {
                      handleSort("inventory_item_supplier_name");
                    }}
                  />
                </span>
                {pageDetails.length > 0 && (
                  <div className="search-col">
                    <input
                      type="text"
                      name="inventory_item_supplier_name_cont"
                      id="inventory_item_supplier_name_cont"
                      className="search-btn"
                      value={search.inventory_item_supplier_name_cont}
                      placeholder="Search"
                      onChange={(e) => {
                        handleTextChanges(e);
                      }}
                    />
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      className="search-icon"
                    />
                  </div>
                )}
              </th>
              <th className="divider">Status</th>
              <th className="action-sticky-col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              pageDetails.length > 0 &&
              pageDetails.map((data, index) => {
                const isItemExpanded = isExpanded(data.order_request_id);
                return (
                    <tr>
                      <td colSpan={24}>
                        <Accordion expanded={isItemExpanded} style={{boxShadow: "none"}} >
                          <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              onClick={(event) => {
                                handleClickExpand(event, data.order_request_id);
                              }}
                          >
                            <Typography>
                              {isItemExpanded ? (
                                  <i className="fa fa-chevron-down" style={{ fontSize: '12px' }}></i>
                              ) : (
                                  <i className="fa fa-chevron-right" style={{ fontSize: '12px' }}></i>
                              )}
                              <span style={{ paddingLeft: '15px', fontSize: '14px' }}>
                                Order #{data && data.order_request_id ? data.order_request_id : 'Unknown'}
                              </span> 
                              <span style={{ paddingLeft: '25px', fontSize: '12px' }}>
                                <b>Group:</b> {(data && data.items && data.items.length > 0 && data.items[0].order_request && data.items[0].order_request.group) ? data.items[0].order_request.group.name : 'No Group / Project Site Selected'}
                              </span>




                            </Typography>
                          </AccordionSummary>
                        <AccordionDetails>
                          <table>
                            <thead>
                            <tr>
                              <th>Order ID</th>
                              <th>Inventory Name</th>
                              <th>Request Qty</th>
                              <th>Requested By</th>
                              <th>Group</th>
                              <th>Supplier Name</th>
                              <th>Status</th>
                              <th className="action-sticky-col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            { data.items.map((item, index) => {
                              return (
                              <tr>
                                <td>
                                  <div className="star-unstar">
                                    <input
                                        type="checkbox"
                                        id="vehicle1"
                                        name="vehicle1"
                                        className="check-box"
                                        checked={isSelected(item.identifier)}
                                        onClick={() => {
                                          handleMultiSelect(item);
                                        }}
                                    />
                                    <span>
                                      #{item.order_request && item.order_request.id}
                                    </span>
                                    {item.order_request && item.order_request.is_urgent && (
                                        <span className="urgent">Urgent</span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="barcode">
                                    <div className="name-content d-flex">
                                      <img
                                          src={
                                            item.inventory_item && item.inventory_item.image_url
                                                ? item.inventory_item.image_url
                                                : DefaultInventory
                                          }
                                          alt="image url"
                                          style={{
                                            width: "54px",
                                            height: "54px",
                                            marginRight: "5px",
                                            objectFit: "cover",
                                          }}
                                      />
                                      <div className="img-content">
                                        <span
                                            className="name"
                                            onClick={(e) => {
                                              item.inventory_item &&
                                              goTo(
                                                  `/view-inventory/${item.inventory_item.identifier}`
                                              );
                                            }}
                                        >
                                          {item.inventory_item && item.inventory_item.name}
                                        </span>
                                        <span
                                            className="barcode-no"
                                            style={{textDecoration: "none"}}
                                        >
                                          {item.inventory_item &&
                                              item.inventory_item.serial_no}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {item.quantity}
                                </td>
                                <td>
                                  <p className="action-by">
                                    {item.order_request &&
                                        item.order_request.created_by &&
                                        item.order_request.created_by.full_name}
                                  </p>
                                  <p className="action-time">
                                    {item.order_request && item.order_request.created_at}
                                  </p>
                                </td>
                                <td>
                                  {item.order_request && item.order_request.group && item.order_request.group.name}
                                </td>
                                <td>
                                  {item.supplier && item.supplier.name}
                                </td>
                                <td>
                                  <span className="pending-order">{item.status}</span>
                                </td>
                                <td className="sticky-col-3">
                                  <div className="pending-approve-action">
                                    <button
                                        className="confirm"
                                        onClick={() => {
                                          setShowDetails([item]);
                                          setShow(true);
                                        }}
                                    >
                                      Approve
                                    </button>
                                    <button
                                        className="decline"
                                        onClick={() => {
                                          decline(item);
                                        }}
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              )
                            })
                            }
                            </tbody>
                          </table>
                        </AccordionDetails>
                      </Accordion>
                      </td>
                    </tr>
                );
              })}
            <Loader pageDetails={pageDetails} loading={loading} colSpan={14} />
          </tbody>
        </table>
      </div>
      <div className="page-limits">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLoading(true);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={pageCount || 1}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
      </div>
      {show && <OrderApprove show={show} close={close} details={showDetails} />}
    </div>
  );
};

export default withRouter(OrderRequest);
