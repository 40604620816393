import React, { useEffect, useState } from "react";
import { Modal, FormControl as Input } from "react-bootstrap";
import { toast } from "react-toastify";
import { TextField, Autocomplete, Checkbox } from "@mui/material";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";
import AcceptIcon from "../../../../assets/fonts/Accept.svg";

export default function OrderApprove(props) {
  const { show, close, details } = props;
  console.log("details===>", details);
  const [pickers, setPickers] = useState([]);
  const [selectedPicker, setSelectedPicker] = useState();
  const [notes, setNotes] = useState();
  const [quantity, setQuantity] = useState([]);
  const [onSubmit, setSubmit] = useState(false);
  const [isSuppliedByThirdParty, setIsSuppliedByThirdParty] = useState(false);
  const [noteHistory, setNoteHistory] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false); 
  const [isAlertDisabled, setIsAlertDisabled] = useState(false);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    const quantities = details.map((val) => {
      return {
        identifier: val.identifier,
        place_order_quantity: "",
        quantity_tracking_disabled:
          val.inventory_item.quantity_tracking_disabled,
      };
    });
    setQuantity(quantities);
    (async () => {
      try {
        await axios
          .get(`${Application.api_url}/api/v2/inventory_pickers`, {
            params: {
              page: 1,
              limit: 10000,
              is_active_eq: true,
            },
          })
          .then((res) => {
            if (res.data && res.data.success === true) {
              let details = res.data;
              setPickers(details ? details.data : []);
            }
          })
          .catch((err) => console.log("err=>", err));
      } catch (err) {
        console.log("err==>", err);
      }
    })();
  }, []);

  const update = async () => {
    try {
      if (!isSuppliedByThirdParty && !selectedPicker) {
        setSubmit(true);
        return false;
      }

      let check = quantity.find(
        (val) =>
          val.place_order_quantity == "" && !val.quantity_tracking_disabled
      );

      if (check && Object.keys(check).length > 0) {
        toast.info("Please fill the place order quantity");
        return false;
      }

      await axios
        .put(
          `${Application.api_url}/api/v2/order_request_items/bulk_assign_picker`,
          {
            requested_picker_identifiers: selectedPicker,
            note: notes,
            order_request_items: quantity,
            is_supplied_by_3rd_party: isSuppliedByThirdParty,
            status: "confirmed",
          }
        )
        .then((res) => {
          if (res.data && res.data.success === true) {
            close(details);
          }
        })
        .catch((error) => toast.error(error.response.data.message));
    } catch (err) {
      console.log("err==>", err);
    }
  };

  const handlePickerAssign = async (value) => {
    let identifier = value.map((val) => val.user.identifier);
    setSelectedPicker(identifier);
  };

  const handleChangeQuantity = (identifier, value, qty) => {
    if ((!qty || Number(value) <= qty) && Number(value) > 0) {
      let checkIndex = quantity.findIndex(
        (val) => val.identifier == identifier
      );
      if (checkIndex != -1) {
        quantity[checkIndex] = {
          identifier: identifier,
          place_order_quantity: value,
          status: Number(value) < qty ? "partially_approved" : "confirmed",
        };

        setQuantity([...quantity]);
      } else {
        setQuantity([
          ...quantity,
          ...[
            {
              identifier: identifier,
              place_order_quantity: value,
              status: Number(value) < qty ? "partially_approved" : "confirmed",
            },
          ],
        ]);
      }
    }
  };
  

  const alertAllPickers = async () => {
    try {
      await axios
        .post(`${Application.api_url}/api/v2/inventories/order_request_alert_pickers`, {
          order_request_items: details.map((item) => item.identifier),
        })
        .then((res) => {
          if (res.data && res.data.success === true) {
            toast.success("Alert sent to all pickers successfully");
          }
        })
        .catch((error) => toast.error("Failed to alert pickers"));
    } catch (err) {
      console.log("err==>", err);
    }
  };

  const saveNotes = async () => {
    try {
      await axios
        .post(`${Application.api_url}/api/v2/inventories/save_notes`, {
          order_request_items: details.map((item) => item.identifier),
          notes: notes,
        })
        .then((res) => {
          if (res.data && res.data.success === true) {
            toast.success("Notes saved successfully");
          }
        })
        .catch((error) => toast.error("Failed to save notes"));
    } catch (err) {
      console.log("err==>", err);
    }
  };


  const fetchNoteHistory = async () => {
    try {
      await axios
        .get(`${Application.api_url}/api/v2/inventories/note_history`, {
          params: { order_request_items: details.map((item) => item.identifier) }
        })
        .then((res) => {
          if (res.data && res.data.success === true) {
            setNoteHistory(res.data.history); // Assuming the response contains a 'history' array
          }
        })
        // .catch((error) => toast.error("Failed to load note history"));
    } catch (err) {
      console.log("err==>", err);
    }
  };
  
  useEffect(() => {
    fetchNoteHistory();
  }, []);
  

  return (
    <Modal
      bsSize="large"
      show={show}
      onHide={(e) => {
        close();
      }}
      className="new-model-popup inventory-approve-req"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img src={AcceptIcon} alt="Accept Icon" />
          Assign the Picker
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="page-content">
          <table>
            <thead>
              <tr>
                <th className="divider">Selected Inventory</th>
                <th className="divider">Supplier Name</th>
                <th className="divider">Request Qty</th>
                <th>Place Order</th>
              </tr>
            </thead>
            <tbody>
              {details &&
                details.length > 0 &&
                details.map((val, index) => {
                  const foundQuantity = quantity.find(
                    (item) => item.identifier == val.identifier
                  );
                  return (
                    <tr>
                      <td>{val.inventory_item && val.inventory_item.name}</td>
                      <td>{val.supplier && val.supplier.name}</td>
                      <td>{val.quantity}</td>
                      <td>
                        {val.inventory_item &&
                        !val.inventory_item.quantity_tracking_disabled ? (
                          <input
                            type="text"
                            name="search"
                            id="search"
                            className="new-input"
                            value={
                              foundQuantity && !isNaN(parseInt(foundQuantity.place_order_quantity, 10))
                                ? parseInt(foundQuantity.place_order_quantity, 10)
                                : ''
                            }
                            onChange={(e) =>
                              handleChangeQuantity(
                                val.identifier,
                                e.target.value,
                                val.quantity
                              )
                            }
                          />
                        ) : (
                          <p>Quantity tracking disabled</p>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="order-approve-fields">
          <div className="fields" style={{ width: "100%" }}>
            <div className="row">
              <div className="col-md-6">
                <label className="label-name">
                <Checkbox
                  {...label}
                  checked={isSuppliedByThirdParty}
                  onChange={(event) => {
                    setIsSuppliedByThirdParty(event.target.checked);
                  }}
                />{" "}
                Supplied by 3rd party
                </label>
              </div>
              <div className="col-md-6">
                <label className="label-name">
                  <button className="btn btn-primary" onClick={alertAllPickers} disabled={isAlertDisabled}  >
                    Alert all pickers to review before approving.
                  </button>
                  <br />
                  <span style={{fontSize:'10px'}}>Not sure of quantity levels in the yard, let a picker assist.</span>
                </label>
              </div>
            </div>
            
            
          </div>
          <div className="fields">
            <label className="label-name">
              Select Picker <text style={{ color: "red" }}>*</text>
            </label>
            <Autocomplete
              disablePortal
              disabled={isSuppliedByThirdParty}
              id="combo-box-demo"
              className="auto-completed"
              options={pickers}
              multiple
              getOptionLabel={(options) => options.user.full_name}
              renderOption={(props, options) => (
                <li
                  {...props}
                  key={options.user.identifier}
                  style={{ fontSize: 14 }}
                >
                  {options.user.full_name}
                </li>
              )}
              sx={{ width: "100%" }}
              onChange={(event, value) => {
                
                handlePickerAssign(value);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          {onSubmit && !selectedPicker && !isSuppliedByThirdParty && (
            <p className="required-fields">Please select the picker</p>
          )}
          <div className="page-content mb-3">
            <table>
              <thead>
                <tr>
                  <th className="divider" style={{maxWidth:"2px"}}>Order ID</th>
                  <th className="divider">Inventory</th>
                  <th className="divider">group</th>
                  <th className="divider">Buyer's Notes</th>
                </tr>
              </thead>
              <tbody>
                {details &&
                  details.length > 0 &&
                  details.map((val, index) => {
                    
                    const foundQuantity = quantity.find(
                      (item) => {
                        debugger;
                        item.identifier == val.identifier
                      }
                    );
                    return (
                      <tr>
                        <td>{val.order_request && val.order_request.id}</td>
                        <td>{val.inventory_item && val.inventory_item.name}</td>
                        <td>{val.order_request && val.order_request.group && val.order_request.group.name}</td>
                        <td style={{maxWidth:"40px"}}>
                          {val.order_request && val.order_request.note_content}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="fields">
            <label className="label-name">Notes/Comments</label>
            <textarea
              className="create-asset-textarea"
              aria-label="empty textarea"
              name="notes"
              id="notes"
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={saveNotes}
            >Save Note</button>
          </div>
          <div className="accordion" style={{ marginTop: "15px", borderRadius: "5px", border: "1px solid #ddd" }}>
            <div
              className="accordion-header"
              onClick={() => setAccordionOpen(!accordionOpen)}
              style={{
                cursor: "pointer",
                backgroundColor: "#f7f7f7",
                padding: "10px 15px",
                borderBottom: accordionOpen ? "1px solid #ddd" : "none",
                borderRadius: "5px 5px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Note History
            </div>
            {accordionOpen && (
              <div className="accordion-body">
                {noteHistory.length > 0 ? (
                  <ul>
                    {noteHistory.map((note, index) => (
                      <li key={index}>
                        <strong>{note.created_at}</strong>: {note.comment}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No notes available.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={(event) => {
            update();
          }}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}
