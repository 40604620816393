import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function DocumentManagement() {
    return (
        <>
            <HeroSection
                heroText={"Document Management"}
                subText={"Organize and store project-related documents with SafetyLoop's document management tools."}
            />
            <FeaturesSection
                headerText={"Secure Document Storage"}
                subText={"Store and organize documents, ensuring quick access and secure management."}
                featureHighlights={[
                    "Maintain a centralized document repository.",
                    "Set permissions for different access levels.",
                    "Track document history and version control.",
                    "Easily search and retrieve important files."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Document Storage section here
            />
            <FeaturesSection
                headerText={"Automated Document Management"}
                subText={"Simplify document workflows with automated processes and templates."}
                featureHighlights={[
                    "Use templates to standardize document creation.",
                    "Automate document approvals and sign-offs.",
                    "Set up alerts for document expiration and updates."
                ]}
                // Placeholder: Add image for Automated Document Management section here
            />
            <FeaturesSection
                headerText={"Document Compliance"}
                subText={"Ensure all your documents meet compliance and regulatory standards."}
                featureHighlights={[
                    "Track document compliance and certification.",
                    "Generate reports for compliance audits.",
                    "Secure sensitive documents with additional encryption."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Document Compliance section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
