import React, { Component, createRef } from "react"
import axios from "axios";

import "../../assets/styles/themes.scss";

import HCaptcha from '@hcaptcha/react-hcaptcha';
import loginImage from "../../../javascript/assets/images/login-splash.jpg";
import Logo from "../../../javascript/assets/images/navbar-logo.png";
import LoginFooter from "./LoginFooter";
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom'
import { toast } from "react-toastify"
import Footer from './Lfooter';

class Login extends Component {
  constructor(props) {
    super(props);

    this.recaptchaMessageRef = createRef()
    this.captchaRef = createRef();
    this.passwordRef = createRef()

    this.state = {
      loginUnsuccessful: false,
      loginSuccessful: false,
      profile_setup_wizard: "",
      fields: { email: "", password: "", remember_me: false },
      errors: {},
      after_login_path: "/users-and-groups",
      captchaToken: "",
      force_update: false
    };

    // This binding is necessary to make `this` work in the callback
    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateLoginError = this.updateLoginError.bind(this);
    this.updateCurrentUser = this.updateCurrentUser.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.togglePassword = this.togglePassword.bind(this)



    var currentIndex = 5; // Initialize outside the interval to keep track of the current index

    setInterval(function() {
        var num = currentIndex;
        console.log(num)
        // Fade out the current image
        $('#login-image-big').fadeOut(2000, function() {
            // Once the fade out is complete, remove the previous class
            $(this).removeClass(function (index, className) {
                return (className.match (/(^|\s)login\S+/g) || []).join(' ');
            });
            // Add the new class to change the background image

            if(num==1) {
              $(this).addClass('login1');
            } else {
              
              $(this).addClass('login' + num);
            }


            // Fade in the new image
            $(this).fadeIn(2000);
        });

        // Increment the index for the next image, resetting to 0 if it reaches 3
        currentIndex = (currentIndex + 1) % 5;
    }, 5000);






  }



  handleCaptchaVerify = (token, eKey) => {
    if (this.recaptchaMessageRef && this.recaptchaMessageRef.current) {
      this.recaptchaMessageRef.current.innerHTML = ""
    }
    this.setState({ captchaToken: token });
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search)
    const isFromCheckout = searchParams.get('from') === 'checkout'
    if (isFromCheckout) {
      toast.success("Successfully create user")
    }

    if (localStorage.chkbx && localStorage.chkbx != "") {
      $("#remember_me").attr("checked", "checked");
      let that = this;
      that.setState({
        fields: { email: localStorage.usrname, password: localStorage.pass },
      });
    } else {
      $("#remember_me").removeAttr("checked");
      $("#email").val("");
      $("#password").val("");
    }
  }

  componentWillUnmount() {
    window.location.href = "/home"
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    }

    this.setState({ errors });
    return formIsValid;
  }

  fetchOneSignalId() {
    return window.OneSignal.getUserId(function (userId) {
      return userId;
    });
  }

  isLocalhost() {
    const localhostHostname = ["localhost", "127.0.0.1"]
    return localhostHostname.includes(window.location.hostname)
  }

  async handleLogin(e) {
    e.preventDefault();

    if (!this.handleValidation()) {
      $(".loading").hide();

      return false
    }

    let fields = this.state.fields;
    let that = this;
    let userInfo = {
      user: {
        email: fields["email"],
        password: fields["password"],
      },
    };
    let googleResponse = false;

    try {
      const response = await axios.post("/verify_captcha", {
        captchaToken: this.state.captchaToken,
      });

      googleResponse = response.data.success;
    } catch (error) {
      console.error("Can not verify captcha with error: ", error);
    }

    if (!googleResponse && !this.isLocalhost()) {
      if (this.recaptchaMessageRef && this.recaptchaMessageRef.current) {
        this.recaptchaMessageRef.current.innerHTML = "<span>Please check captcha to continue.</span>"
      }

      return false;
    }

    $(".loading").show();

    $.ajax({
      type: "POST",
      url: "/users/sign_in",
      dataType: "json",
      data: userInfo,
      error: function (error) {
        that.setState({ errors: { invalid_user: error.responseJSON.error.user_authentication } });
        that.captchaRef && that.captchaRef.current && that.captchaRef.current.resetCaptcha()
        $(".loading").hide();
      },
      success: function (res) {
        that.setState({ errors: {} })
        if (res.user.dashboard_positions && res.user.dashboard_positions.columns) {
          res.user.dashboard_positions.columns = Object.keys(res.user.dashboard_positions.columns).map((key) => res.user.dashboard_positions.columns[key]);
          Object.keys(res.user.dashboard_positions.titles).forEach(function (key, index) {
            res.user.dashboard_positions.titles[key] = JSON.parse(res.user.dashboard_positions.titles[key])
          });
          res.user.dashboard_positions.banner = res.user.dashboard_positions.banner ? JSON.parse(res.user.dashboard_positions.banner) : false;
        }

        localStorage.setItem("user", JSON.stringify(res.user))
        localStorage.setItem("role", JSON.stringify(res.role))
        localStorage.setItem('user_id', res.user.id)
        localStorage.setItem('user_eula_status', res.user.eula_sign_pending)

        Cookies.set('token', res.authenticate_token);

        if ($("#remember_me").is(":checked")) {
          localStorage.usrname = $("#email").val();
          localStorage.pass = $("#password").val();
          localStorage.chkbx = $("#remember_me").val();
        } else {
          localStorage.usrname = "";
          localStorage.pass = "";
          localStorage.chkbx = "";
        }
        if (res.user.is_new_user) {
          localStorage.email = res.user.username;
        }

        localStorage.token = res.company_name;

        User.role = res.role.name;
        User.company = res.company_name;
        User.authenticate(() => {
          that.setState({
            loginSuccessful: true,
            profile_setup_wizard: res.profile_setup_wizard,
            after_login_path: res.after_sign_in_path,
            force_update: res.user.is_new_user,
          });
        });

        let oneSignalId;
        try {
          oneSignalId = that.fetchOneSignalId();
        } catch (error) {
          oneSignalId = "";
          console.log(error);
          $(".loading").hide();
        }

        let data = {
          user: {
            push_token: oneSignalId,
            platform_name: "web",
          },
        };

        $.ajax({
          type: "POST",
          url: "/device",
          dataType: "json",
          data: data,
        });

        window.pathname = "/";
      },
    });
  }

  handleChange(field, e) {
    let fields = this.state.fields;

    if (field === "remember_me") {
      fields[field] = e.target.checked;
    } else {
      fields[field] = e.target.value;
    }

    this.setState({ fields });
  }

  updateLoginError() {
    this.setState({ loginUnsuccessful: true });
  }

  updateCurrentUser(email) {
    this.setState({ currentUser: email });
  }

  togglePassword(event) {
    const type = this.passwordRef.current.getAttribute("type") === "password" ? "text" : "password";
    this.passwordRef.current.setAttribute("type", type);

    event.target.classList.toggle("fa-eye-slash");
  }

  render() {
    if (this.state.loginSuccessful) {
      $(".loading").hide();

      if (this.state.profile_setup_wizard) {
        window.location.href = "/company_setup_wizards/setup_profile_section";
      } else {
        window.location.href = this.state.force_update
          ? "/update_password"
          : this.state.after_login_path;
      }
    }

    return (
      <div className="main-wrap user-login-page" style={{ padding: 0 }}>
        <div className="row new-login-page">
          <div className="home-container login-page-revamp">
          <div className="login-image col-md-7 col-sm-12">
          <div id="login-image-big" className="login">

          </div>
        </div>

            <div className="login-form col-md-5 col-sm-12">
              <div className="login-details" style={{ maxWidth: "510px" }}>
              <div className="logo-container">
                <img src={Logo} className="login-logo" alt="Company Logo" />
              </div>
                <div className="text-center login-header login-sub-header">Log in to your Account</div>

                <form className="m-t-3-login">
                  <div className="form-group">
                    <input
                      className="form-control  login-input2"
                      ref="email"
                      id="email"
                      maxLength="70"
                      placeholder="Enter your username / email"
                      onChange={(event) => this.handleChange('email', event)}
                      value={this.state.fields["email"]}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["email"]}
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control login-input2"
                      ref={this.passwordRef}
                      type="password"
                      maxLength="50"
                      id="password"
                      placeholder="Enter your password"
                      onChange={(event) => this.handleChange('password', event)}
                      value={this.state.fields["password"]}
                    />
                    <i
                      className="fa fa-eye fa-eye-slash"
                      style={{
                        marginBottom: "10px",
                        marginTop: "-25px",
                        float: "right",
                        paddingRight: "10px",
                        cursor: "pointer",
                        display: "block",
                      }}
                      onClick={this.togglePassword}
                    />
                    <div className="row">
                      <div className="col-md-6" style={{paddingTop:'4px'}}>
                        <a href="/forget-password" id="forget-password-link" style={{ color: "#264153" }}>
                          Forgot Password?
                        </a>
                      </div>
                      <div className="col-md-6">
                        <div className="remember_me">
                          <label>
                            <input
                              id="remember_me"
                              type="checkbox"
                              onChange={(event) => this.handleChange('remember_me', event)}
                              value={this.state.fields["remember_me"]}
                              className="remember-me"
                            />
                            Remember Me
                          </label>
                        </div>
                      </div>

                    </div>
                    
                   
                      
                    <span style={{ color: "red", paddingTop: "10px" }}>
                      {this.state.errors["password"]}
                    </span>
                  </div>
                  <span style={{ color: "red" }}>
                    {this.state.errors["invalid_user"]}
                  </span>
                  <div className="hcaptcha2">
                    <div id="recpatcha-message" ref={this.recaptchaMessageRef} />
                    <div id="recpatcha-success" />
                    <div className="row d-sm-flex">
                      <div
                        className="col-lg-8 col-md-12 col-sm-12"
                        style={{
                          transform: "scale(0.8)",
                          transformOrigin: "left",
                          marginTop: '25px'
                        }}
                      >
                          <HCaptcha
                            ref={this.captchaRef}
                            sitekey="806d128e-7999-4a5e-ad89-74a73e6ab9f0"
                            onVerify={this.handleCaptchaVerify}
                          />
                      </div>
                      
                    </div>
                    
                  </div>
                  <div className="login-addon-container">
                    
                    
                  </div>

                  <button
                    className="btn btn-block m-b-2 btn-primary revamp-login-btn"
                    id="user-login"
                    onClick={this.handleLogin}
                    style={{ backgroundColor: "#264153 !important" }}
                  >
                    Login
                  </button>
                  
                  
                </form>
                <div className="account-signup-text">
                   
                      <button
                        className="btn btn-block m-b-2 btn-primary revamp-login-btn"
                      
                        onClick={() => window.location.href = "/pricing"}
                        style={{ backgroundColor: "#264153 !important" }}
                      >
                      Create new Company Account
                      </button>

                   
                  </div>
                <div className="back-to-home">
                  <a href="/home" style={{ color: "#264153" }}>Back to Home</a>
                </div>
                {/* <span style={{ float: "right", fontSize: "10px" }}>
                    v{User.version}
                  </span> */}
                <Footer />
              </div>
              {/* </div> */}
              
            </div>
            
          </div>
          
        </div>
        
        
      </div>
    );
  }
}
export default withRouter(Login)

const INVENTORY_KEY = "inventory";
const TIME_TRACKER_KEY = "time tracking";

export const User = {
  isAuthenticated: false,
  version: "2.6.3",
  role: "",
  company: "",
  username: username,
  isEmployee() {
    return this.role.toLowerCase() === "employee";
  },
  isAdmin() {
    return this.role.toLowerCase() === "admin";
  },
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  isInventoryEnabled() {
    if (this.isEmployee()) return false;
    if (this.isAdmin() && !this.companyInfo.inventory_enabled) return true; // Admin can access to show paywall

    if (!this.companyInfo.inventory_enabled) return false;

    const menuItem = this.menuItems.find((item) => item.key.toLowerCase() === INVENTORY_KEY);
    if(!menuItem) return false;

    return menuItem.visibility;
  },
  showPaywall() {
    return !this.companyInfo.inventory_enabled;
  },
  isTimeTrackingEnabled() {
    if (this.isEmployee()) return false;
    if (this.isAdmin() && !this.companyInfo.time_tracker_enabled) return true; // Admin can access to show paywall

    if (!this.companyInfo.time_tracker_enabled) return false;

    const menuItem = this.menuItems.find((item) => item.key.toLowerCase() === TIME_TRACKER_KEY);
    if(!menuItem) return false;

    return menuItem.visibility;
  },
  showTimeTrackerPaywall() {
    return !this.companyInfo.time_tracker_enabled;
  }
};
