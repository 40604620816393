import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function InspectionManagement() {
    return (
        <>
            <HeroSection
                heroText={"Inspection Management"}
                subText={"Conduct and manage inspections effectively with SafetyLoop's inspection management tools."}
            />
            <FeaturesSection
                headerText={"Efficient Inspection Tracking"}
                subText={"Easily track and monitor all safety inspections, ensuring compliance and safety standards."}
                featureHighlights={[
                    "Create custom inspection checklists.",
                    "Schedule and assign inspections to team members.",
                    "Track inspection progress and status.",
                    "Generate reports for audits and compliance."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Inspection Tracking section here
            />
            <FeaturesSection
                headerText={"Automated Inspection Alerts"}
                subText={"Receive real-time alerts and reminders for upcoming inspections."}
                featureHighlights={[
                    "Set automated alerts for pending inspections.",
                    "Receive notifications for overdue inspections.",
                    "Track inspection results and compliance status."
                ]}
                // Placeholder: Add image for Automated Alerts section here
            />
            <FeaturesSection
                headerText={"Detailed Inspection Reports"}
                subText={"Generate detailed reports and maintain records of all inspections for compliance."}
                featureHighlights={[
                    "Store inspection history in a secure database.",
                    "Generate compliance-ready inspection reports.",
                    "Easily share inspection results with stakeholders."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Detailed Reports section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
