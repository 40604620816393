import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function InventoryManagement() {
    return (
        <>
            <HeroSection
                heroText={"Inventory Management"}
                subText={"Track and manage inventory supplies with SafetyLoop's inventory management tools."}
            />
            <FeaturesSection
                headerText={"Comprehensive Inventory Tracking"}
                subText={"Monitor inventory levels, usage, and replenishment in real-time."}
                featureHighlights={[
                    "Track inventory across multiple locations.",
                    "Set up alerts for low inventory levels.",
                    "Monitor usage and order history.",
                    "Generate detailed inventory reports."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Inventory Tracking section here
            />
            <FeaturesSection
                headerText={"Automated Inventory Management"}
                subText={"Streamline inventory management with automated workflows and alerts."}
                featureHighlights={[
                    "Automate inventory replenishment and orders.",
                    "Set up alerts for replenishment thresholds.",
                    "Monitor inventory costs and usage patterns."
                ]}
                // Placeholder: Add image for Automated Management section here
            />
            <FeaturesSection
                headerText={"Inventory Reporting and Compliance"}
                subText={"Generate compliance-ready inventory reports for audits and safety management."}
                featureHighlights={[
                    "Generate reports for inventory audits.",
                    "Track compliance with inventory safety standards.",
                    "Maintain a secure history of inventory data."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Reporting and Compliance section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
