import React from 'react';
import { Button } from 'react-bootstrap';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import HeroBannerImage from "../../../assets/images/hp_guy.png";
import HeroBannerImage2 from "../../../assets/images/Ellipse2.png";
import HeroBannerImage3 from "../../../assets/images/iso2.png";
import HeroBannerImage4 from "../../../assets/images/hp_orange.png";
import HeroBannerImage5 from "../../../assets/images/insights_1.png";
import HeroBannerImage6 from "../../../assets/images/form_type.png";
// import HeroBannerImage1 from "../../../assets/images/HeroBannerImage1.png";
// import HeroBannerImage2 from "../../../assets/images/HeroBannerImage2.png";
// import HeroBannerImage3 from "../../../assets/images/HeroBannerImage3.png";
// import HeroBannerImage4 from "../../../assets/images/HeroBannerImage4.png";
// import HeroBannerImage5 from "../../../assets/images/HeroBannerImage5.png";
// import HeroBannerImage6 from "../../../assets/images/HeroBannerImage6.png";

const ratingUrl = "https://www.capterra.com/reviews/246063/SafetyLoop?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge";

const ratingUrls = {
    capterra: "https://www.capterra.com/reviews/246063/SafetyLoop?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge",
    getApp: "https://www.getapp.com/operations-management-software/a/safetyloop/reviews/",
    softwareAdvice: "https://www.softwareadvice.ie/software/501273/SafetyLoop"
};
export default function HomePageHeroSection() {



    const heroTitles = [
        "Safety Compliance",
        "Compliance Simplified",
        "Compliance Solved",
        "Optimizing Safety",
        "Streamlined Safety",
        "Next-Gen Compliance",
        "Effortless Safety",
        "Compliance Mastery"
    ];
    
    const heroSubtexts = [
        "SAFETY MADE EASIER",
        "PROTECTING YOU SIMPLY",
        "SAFETY AT YOUR FINGERTIPS",
        "KEEPING YOU SAFE MADE SIMPLE",
        "INNOVATION THAT SAVES LIVES",
        "SOLUTIONS THAT PROTECT",
        "EFFORTLESS SAFETY COMPLIANCE",
        "SEAMLESS SAFETY INTEGRATION"
    ];
    
    
    const heroContentTexts = [
        "Protect your team with SafetyLoop's dynamic software. Streamline safety operations effortlessly, making compliance a natural part of keeping everyone safe every day.",
        "Empower your safety management with SafetyLoop. Our intuitive software transforms complex compliance into easy-to-handle tasks, always keeping your team's well-being at the forefront.",
        "Revolutionize your workplace safety with SafetyLoop's all-in-one platform. Our solutions bring clarity and efficiency to your safety efforts, ensuring everyone goes home safe.",
        "Achieve peace of mind with SafetyLoop’s intelligent software. Seamlessly integrate safety into your daily workflow, with real-time insights that protect what matters most—your people.",
        "Transform how you safeguard your team with SafetyLoop. Our innovative tools offer a comprehensive approach to compliance, giving your team the power to maintain a safe environment.",
        "Embrace a safer future with SafetyLoop. Our cutting-edge software combines advanced technology with ease of use, making safety management more personal and effective than ever."
    ];
    
    
    

    // Function to randomly select a text from an array
    const getRandomText = (texts) => {
        return texts[Math.floor(Math.random() * texts.length)];
    };

    return (
        <div className="hero-section-container2">
            <div className="text-content">
                <h1 className="hero-title">{getRandomText(heroTitles)}</h1>
                <p className="hero-subtitle">{getRandomText(heroSubtexts)}</p>
                <p className="hero-description">
                    {getRandomText(heroContentTexts)}
                </p>
                <div className="hero-buttons">
                    <a href="/contact">
                        <Button className="book-demo-btn">Book Demo</Button>
                    </a>
                    <a href="/features">
                        <Button className="all-features-btn">See All Features</Button>
                    </a>
                </div>
                <div className="rating-section">
                    <div className="rating">
                        <a href={ratingUrls.capterra} target="_blank" rel="noopener noreferrer">
                            <img src="https://assets.capterra.com/badge/3c084a585dbd8a18109cb49be84b6bf7.svg?v=2203506&p=246063" style={{width:'190px'}}   alt="Capterra Rating" />
                        </a>
                    </div>
                    <div className="rating">
                        <a href={ratingUrls.getApp} target="_blank" rel="noopener noreferrer">
                            <img src="https://brand-assets.getapp.com/badge/fe9aded2-7100-4e8c-ba7e-f3736937e112.png" style={{height:"64px", width:'100px'}} alt="GetApp Rating" />
                        </a>
                    </div>
                    <div className="rating">
                        <a href={ratingUrls.softwareAdvice} target="_blank" rel="noopener noreferrer">
                            <img src="https://brand-assets.softwareadvice.com/badge/73ed112e-c31c-4761-b78b-6adfc47782d2.png" style={{width:'170px', position:'absolute'}}   alt="Software Advice Rating" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="image-content">
                <img src={HeroBannerImage} alt="Compliance Simplified" className="hero-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage2} alt="Compliance Simplified" className="ellipse-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage3} alt="Compliance Simplified" className="iso-main-image" />
            </div>

            <div className="image-content">
                <img src={HeroBannerImage4} alt="Compliance Simplified" className="orange-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage5} alt="Compliance Simplified" className="insights-main-image" />
            </div>
            <div className="image-content">
                <img src={HeroBannerImage6} alt="Compliance Simplified" className="form-type-main-image" />
            </div>
        </div>
    )
}
