import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function IncidentManagement() {
    return (
        <>
            <HeroSection
                heroText={"Incident Management"}
                subText={"Track, report, and analyze safety incidents with SafetyLoop's comprehensive incident management system."}
            />
            <FeaturesSection
                headerText={"Real-Time Incident Tracking"}
                subText={"Monitor incidents as they happen and ensure quick resolution and compliance."}
                featureHighlights={[
                    "Log incidents in real-time from any device.",
                    "Assign responsibilities and track resolutions.",
                    "Generate detailed reports on incident history.",
                    "Analyze trends to reduce future incidents."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Incident Tracking section here
            />
            <FeaturesSection
                headerText={"Incident Analysis"}
                subText={"Gain insights on safety incidents and proactively manage safety protocols."}
                featureHighlights={[
                    "Identify root causes and implement corrective actions.",
                    "Use data-driven insights to improve safety procedures.",
                    "Monitor safety trends and performance over time."
                ]}
                // Placeholder: Add image for Incident Analysis section here
            />
            <FeaturesSection
                headerText={"Compliance and Reporting"}
                subText={"Ensure compliance with regulatory standards by maintaining detailed records of all incidents."}
                featureHighlights={[
                    "Generate compliance-ready reports for audits.",
                    "Track resolutions and actions taken for each incident.",
                    "Maintain a secure, centralized repository for incident data."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Compliance and Reporting section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
