import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function ContractorManagement() {
    return (
        <>
            <HeroSection
                heroText={"Contractor Management"}
                subText={"Manage contractor information, compliance, and performance with SafetyLoop's comprehensive tools."}
            />
            <FeaturesSection
                headerText={"Comprehensive Contractor Tracking"}
                subText={"Track and monitor contractor details, agreements, and compliance in real-time."}
                featureHighlights={[
                    "Maintain contractor records, certifications, and agreements.",
                    "Track contractor performance and compliance.",
                    "Set alerts for expiring certifications and contracts.",
                    "Centralize contractor management for seamless integration."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // featureImageSrc={placeholder}  // Placeholder: Add image for contractor management tracking here
            />
            <FeaturesSection
                headerText={"Customizable Contractor Profiles"}
                subText={"Create and manage detailed contractor profiles tailored to your organization's needs."}
                featureHighlights={[
                    "Define custom attributes and properties for each contractor.",
                    "Categorize contractors based on skills, certifications, and regions.",
                    "Easily update contractor information from a single interface."
                ]}
                // featureImageSrc={placeholder}  // Placeholder: Add image for contractor profiles here
            />
            <FeaturesSection
                headerText={"Enhanced Contractor Compliance"}
                subText={"Ensure all contractors meet compliance standards and safety requirements."}
                featureHighlights={[
                    "Automate compliance tracking and reporting.",
                    "Set up automated alerts for non-compliance.",
                    "Generate reports for compliance audits."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // featureImageSrc={placeholder}  // Placeholder: Add image for contractor compliance here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
